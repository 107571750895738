const style = {
  name: {
    textAlign: 'center',
  },
  default: {
    fontWeight: 'bold',
  },
  description: {
    textAlign: 'center',
  },
  table: {
    height: 'calc(100vh - 150px)',
  },
};

export default style;
