import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {withStyles} from '@material-ui/core/styles';

import {
  FilterWithPlaceholderAndFullTextFilterMethod,
  EntitiesListWithViewModesCard,
} from '../index';

import style from './style';
import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonCreate: [ACCESS_RIGHTS.BLOCK_PAGE_CREATE],
};

class BlockPagesTable extends Component {
  constructor(props) {
    super(props);

    this.blockPageCellRenderer = (row) => (
      <span style={row.original.blockPageDefault ? style.default : {}}>
        {row.value}
      </span>
    );

    this.headersFullWidthMode = [];
    this.headersCompressedMode = [];

    this.headersFullWidthMode.push(
      {
        Header: I18n.t('blockPage.blockPageNameHeader'),
        accessor: 'blockPageName',
        ...BlockPagesTable.getFilter(),
        sortable: false,
        filterable: true,
        style: style.name,
        headerStyle: style.name,
        Cell: this.blockPageCellRenderer,
      },
      {
        Header: I18n.t('blockPage.blockPageDescriptionHeader'),
        accessor: 'blockPageDescription',
        filterable: false,
        sortable: false,
        style: style.description,
        headerStyle: style.description,
        Cell: this.blockPageCellRenderer,
      },
      {
        accessor: 'blockPageDefault',
        filterable: false,
        sortable: false,
        width: 0,
      },
    );
    this.headersCompressedMode.push({
      Header: null,
      accessor: 'blockPageName',
      sortable: false,
      ...BlockPagesTable.getFilter(),
      style: style.name,
      headerStyle: style.name,
      Cell: this.blockPageCellRenderer,
    },
    {
      accessor: 'blockPageDefault',
      filterable: false,
      sortable: false,
      width: 0,
    });
  }

  static getFilter = () => (
    FilterWithPlaceholderAndFullTextFilterMethod(
      I18n.t('blockPage.blockPageNameFilterPlaceholder'),
      ['blockPageName'],
    )
  );

  render() {
    const {
      mode,
      handleClickButtonNew,
      handleClickBlockPageRow,
      isEditMode,
      tableData,
      selectedBlockPageIndex,
    } = this.props;
    return (
      <EntitiesListWithViewModesCard
        mode={mode}
        headersFullWidthMode={this.headersFullWidthMode}
        headersCompressedMode={this.headersCompressedMode}
        tableData={tableData}
        disabled={isEditMode}
        title={I18n.t('blockPage.blockPageTableTitle')}
        entityType={I18n.t('entitiesTypes.blockPage')}
        defaultSorted={[{
          id: 'blockPageDefault',
          desc: true,
        }]}
        selectedEntityIndex={selectedBlockPageIndex}
        handleClickButtonNew={handleClickButtonNew}
        handleClickRow={handleClickBlockPageRow}
        accessRights={rightAvailabilityMap}
        resizable={false}
        showOnAccessDenyButtonCreate={true}
        newButtonText={I18n.t('blockPage.buttonAddNewBlockPage')}
        newButtonTextShort={I18n.t('blockPage.buttonAddNewBlockPage')}
        tableStyles={style.table}
      />
    );
  }
}

BlockPagesTable.propTypes = {
  handleClickButtonNew: PropTypes.func.isRequired,
  handleClickBlockPageRow: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  tableData: PropTypes.array,
  selectedBlockPageIndex: PropTypes.number.isRequired,
};

BlockPagesTable.defaultProps = {
  tableData: [],
};

export default withStyles(style)(BlockPagesTable);
