import {
  defaultFont,
  editColor,
  greenColor,
  primaryColor,
  redColor,
  secondaryColor,
  editModeDisabledStyle,
} from '../style';

const style = {
  card: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    margin: '0',
    verticalAlign: 'top',
  },
  card_logOperatorFilterPanel: {
    overflow: 'visible',
  },
  card__client: {
    height: 'unset',
  },
  card_disabled: {
    ...editModeDisabledStyle,
  },
  card_fullScreen: {
    // height: `calc(100vh - ${headerHeight})`,
    height: '100%',
  },
  card_networks: {
    height: '100%',
  },
  card_subnets: {
    height: '100%',
  },
  card_relays: {
    height: '100%',
  },
  card_campusInfo: {
    height: 'calc(100vh - 600px)',
  },
  card_organizationInfo: {
    height: '100%',
  },
  card_performance: {
    height: '100%',
  },
  card_notificationType: {
    margin: '10px',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  card_status: {
    height: '100%',
  },
  card_whitelist: {
    height: '100%',
  },
  card_tldFilter: {
    height: '100%',
  },
  card_policyEditFormTldList: {
    'flex': '0 auto',
    'width': 'calc(50% - 28px)',
    'display': 'flex',
    'flexDirection': 'column',
    '@media (max-width: 1250px)': {
      width: '100%',
    },
  },
  card_policiesAssignedTable: {
    maxHeight: '300px',
  },
  card__header: {
    padding: '15px 20px 0',
    borderRadius: '3px',
  },
  card__header_policyEditForm: {
    padding: '0',
  },
  card__subheader: {
    ...defaultFont,
    color: primaryColor,
    fontSize: '14px',
    margin: '6px 0',
  },
  card__content: {
    position: 'relative',
    padding: '9px 20px 5px 20px !important',
  },
  notificationCard__content: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'pre',
    textAlign: 'center',
  },
  card__content_blockpage: {
    overflowY: 'auto',
    height: 'calc(100vh - 150px)',
  },
  card__content_networks: {
    overflowY: 'auto',
  },
  card__content_relays: {
    overflowY: 'auto',
  },
  card__content_campus: {
    overflowY: 'auto',
    height: 'calc(100vh - 145px)',
  },
  card__card_clientBundle: {
    overflowY: 'auto',
    height: 'calc(100vh - 245px)',
  },
  card__content_client: {
    overflowY: 'auto',
    height: 'calc(100vh - 163px)',
  },
  card__content_clientProfile: {
    overflowY: 'auto',
    height: 'calc(100vh - 173px)',
  },
  card__content_organization: {
    overflowY: 'auto',
    height: 'calc(100vh - 143px)',
  },
  card__content_organizationInfo: {
    overflowY: 'auto',
    height: 'calc(100vh - 705px)',
  },
  card__content_organizationInfo_noDashboard: {
    overflowY: 'auto',
    height: 'calc(100vh - 355px)',
  },
  card_organizationSubtab: {
    overflowY: 'auto',
    height: 'calc(100vh - 652px)',
  },
  card_organizationSubtab_noDashboard: {
    overflowY: 'auto',
    height: 'calc(100vh - 302px)',
  },
  card__content_organizationSubtab: {
    overflowY: 'auto',
  },
  card__content_organizationSubtab_noDashboard: {
    overflowY: 'auto',
  },
  card__content_persona: {
    overflowY: 'auto',
    height: 'calc(100vh - 163px)',
  },
  card__content_personaProfile: {
    overflowY: 'auto',
    height: 'calc(100vh - 173px)',
  },
  card__content_policy: {
    height: 'calc(100vh - 143px)',
  },
  card__content_campusInfo: {
    overflowY: 'auto',
    height: '100%',
  },
  card__content_policyEditForm: {
    padding: '10px 2px 10px !important',
  },
  card__content_policyEditFormTldList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  card__content_policiesAssignedTable: {
    overflowY: 'auto',
    maxHeight: '252px',
    minHeight: '76px',
  },
  card__content_notificationsList: {
    'display': 'flex',
    '@media (max-width: 960px)': {
      display: 'block',
    },
  },
  card__content_notificationType: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
  },
  card__content_profilePage: {
    '& > div:not(:last-child)': {
      marginBottom: '20px',
    },
  },
  card__persona: {
    height: 'unset',
  },
  card__tableBody: {
    height: 'calc(100vh - 735px)',
  },
  card__title: {
    ...defaultFont,
    'textDecoration': 'none',
    'marginTop': '0',
    'fontSize': '17px',
    '& small': {
      fontWeight: '400',
      lineHeight: '1',
      color: primaryColor,
    },
    'paddingBottom': '6px',
    'borderBottom': `1px solid ${primaryColor}`,
    'display': 'flex',
    'alignItems': 'space-between',
    'width': '100%',
  },
  card__title_groupStats: {
    fontSize: '14px',
    marginBottom: '15px',
  },
  card__title_edit: {
    color: editColor,
    borderBottom: `1px solid ${editColor}`,
  },
  card__title_view: {
    color: primaryColor,
    borderBottom: `1px solid ${primaryColor}`,
  },
  card__title_green: {
    color: greenColor,
    borderBottom: `1px solid ${greenColor}`,
  },
  card__title_red: {
    color: redColor,
    borderBottom: `1px solid ${redColor}`,
  },
  card__title_primary: {
    color: primaryColor,
    borderBottom: `1px solid ${primaryColor}`,
  },
  card__title_secondary: {
    color: secondaryColor,
    borderBottom: `1px solid ${secondaryColor}`,
  },
  cardTitle__entityName: {
    ...defaultFont,
    fontSize: 'inherit',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '300px',
    margin: '0',
  },
  card__actions_edit: {
    position: 'absolute',
    margin: '0',
    right: '20px',
    display: 'flex',
  },
  card__actionBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationCard__button: {
    marginTop: '20px',
  },
  insightsFiltersCard: {
    'height': '100%',
    'width': 'inherit',
    '@media(max-width: 970px)': {
      height: 'unset',
    },
  },
  insightsFiltersCard__header_collapsed: {
    '@media(min-width: 970px)': {
      padding: '15px 3px',
      border: 'none',
    },
  },
  insightsFiltersCard__title_collapsed: {
    '@media(min-width: 970px)': {
      border: 'none',
    },
  },
  card_selectableEntityVerticalPairedList: {
    'flex': '0 auto',
    'width': 'calc(50% - 28px)',
    'display': 'flex',
    'flexDirection': 'column',
    '@media (max-width: 1250px)': {
      width: '100%',
    },
  },
  card__content_selectableEntityVerticalPairedList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
};

export default style;
