import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import {EntityEditingCard} from '../index';

import {BlockPageEditForm} from '../../containers/index';

import {ACCESS_RIGHTS} from '../../constants';

const rightAvailabilityMap = {
  buttonDelete: [ACCESS_RIGHTS.BLOCK_PAGE_DELETE],
  buttonEdit: [ACCESS_RIGHTS.BLOCK_PAGE_EDIT],
};

function BlockPageDetails(props) {
  const {
    handleClickButtonCancel,
    handleClickButtonEdit,
    handleClickButtonSave,
    handleClickButtonDelete,
    handleClickButtonBack,
    isValidBlockPageInfo,
    isEditMode,
    title,
  } = props;

  return (
    <EntityEditingCard
      title={title}
      handleClickButtonCancel={handleClickButtonCancel}
      handleClickButtonEdit={handleClickButtonEdit}
      handleClickButtonSave={handleClickButtonSave}
      handleClickButtonDelete={handleClickButtonDelete}
      isValidEntity={isValidBlockPageInfo}
      isEditMode={isEditMode}
      isThereEditBlock={true}
      deleteButtonText={I18n.t('blockPage.deleteBlockPage')}
      editButtonText={I18n.t('blockPage.editBlockPage')}
      isThereDeleteBlock={true}
      isTherePathBack={true}
      handleClickButtonBack={handleClickButtonBack}
      accessRights={rightAvailabilityMap}
      customCardContentClass="card__content_blockpage"
      content={
        <BlockPageEditForm />
      }
    />
  );
}

BlockPageDetails.propTypes = {
  handleClickButtonCancel: PropTypes.func.isRequired,
  handleClickButtonEdit: PropTypes.func.isRequired,
  handleClickButtonSave: PropTypes.func.isRequired,
  handleClickButtonDelete: PropTypes.func.isRequired,
  handleClickButtonBack: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  isValidBlockPageInfo: PropTypes.bool.isRequired,
};

export default BlockPageDetails;
